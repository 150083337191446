import { PageLayout } from "../../components/page-layout"
import logo from "../../static/SEN-Logo-Clear.png"

export default function Privacy() {
    return (
        <div style={{padding:"8px"}}>
        <PageLayout>    
            <img style={{width:"150px",margin:"auto"}} src={logo}/>
            <h1>Privacy Policy</h1>
            
<h2>Effective Date: 4th December 2024</h2>

<h2>1. Introduction</h2>
We are committed to protecting your privacy and ensuring that your personal data is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information in compliance with the ICO guidelines and the General Data Protection Regulation (GDPR).

<h2>2. Information We Collect</h2>
Email Address: This is collected and stored by our identity provider service when you sign up for our app. This will also be collected when using our "contact us" form.
Contact Messages: If you contact us through our support or contact forms, we collect and store the information provided in those messages.
<h2>2.2 Tracking</h2>
Currently we do not use any third party cookies. Google Ads may be present within the app but are not yet active. Cookies can be managed on entry to the site and preferences will be saved. This is for future use as no third party cookies are yet active. We may use track links when contacting users over social media however these do not identify specific users. 
<h2>3. How We Use Your Information</h2>
Email Address: Your email address is used to create your account and authenticate your identity via our authentication provider, Auth0, which is GDPR-compliant.
Contact Messages: We use the information provided in your messages to respond to your inquiries, provide customer support, and improve our services.
<h2>4. Data Storage and Security</h2>
Image Storage: All images uploaded to our app are stored by Backblaze, a GDPR-compliant cloud storage provider.
User Information: All user data, including email addresses, is stored and managed by Auth0, which adheres to GDPR regulations.
Your email will also be stored by us when you send messages via the contact us form and subscribe to our mailing list.
This is stored in a secure GDPR compliant database.
<h2>5. Data Retention</h2>
We retain your personal information only for as long as necessary to provide our services to you and to fulfill our legal obligations. After this period, your data will be securely deleted.

<h2>6. Your Rights</h2>
You have the right to access, rectify, or delete your personal data at any time. You can also request a copy of the information we hold about you or ask us to restrict the processing of your data.

<h2>7. Contact Us</h2>
If you have any questions or concerns regarding this Privacy Policy, or if you wish to exercise any of your rights under GDPR, please contact us via the contact us form (this can be found below in the footer of our site)


<h2>8. Changes to This Privacy Policy</h2>
We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you via email if the changes are significant.
        </PageLayout>
        </div>
    )
    

}
