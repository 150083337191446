import { PageLayout } from "../../components/page-layout"
import logo from "../../static/SEN-Logo-Clear.png"

export default function Terms() {

    return (
        <div style={{padding:"8px"}}>
        <PageLayout >
            <img style={{width:"150px",margin:"auto"}} src={logo}/>    
<h1>Terms and Conditions</h1>
<h2>
Effective Date: 4th December 2024
</h2>
<h2>
1. Acceptance of Terms</h2>
By accessing or using our app, you agree to be bound by these Terms & Conditions. If you do not agree with these terms, please do not use the app.

<h2>2. Use of the App</h2>
Safe Usage: You agree to use the app safely and responsibly. You must not upload any personal information, sensitive data, or any content that could be considered malicious or harmful.
Prohibited Actions: You must not use the app to engage in any unlawful activities, distribute malware or viruses, or infringe on the rights of others.
We do not store any personal data that is not mentioned in our privacy policy - it is the users Responsibility to make sure no content uploaded to this site contains personal information that does not comply with the privacy policy.
<h2>3. User Content</h2>
Ownership: You retain ownership of any content you upload to the app. However, by uploading content, you grant us a license to store and display it as necessary to provide our services.
Responsibility: You are solely responsible for the content you upload and for ensuring that it does not violate any laws or third-party rights.
<h2>4. Data Storage and Privacy</h2>
Storage Providers: All images are stored by Backblaze, and user information is stored by Auth0 (unless stated in the privacy policy), both of which are GDPR-compliant.
Data Handling: We handle all data in accordance with our Privacy Policy, which follows ICO guidelines.
<h2>5. Limitation of Liability</h2>
We are not liable for any loss or damage resulting from your use of the app, including any data breaches, unauthorized access, or third-party actions. We do not guarantee that the app will be error-free or uninterrupted.

<h2>6. Termination</h2>
We reserve the right to suspend or terminate your access to the app at any time, without notice, if you violate these terms or if we believe your actions could harm the app or other users.

<h2>7. Changes to Terms</h2>
We may update these Terms & Conditions from time to time. Any changes will be posted on this page, and we will notify you via email if the changes are significant.

<h2>8. Governing Law</h2>
These Terms & Conditions are governed by and construed in accordance with the laws of the United Kingdom, and you agree to submit to the exclusive jurisdiction of the courts of England and Wales.

<h2>9. Contact Us</h2>
If you have any questions about these Terms & Conditions, please contact us via the "contact us" page (this can be found below in the footer of our page).

        </PageLayout>
        </div>
    )
    
}
  